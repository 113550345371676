/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import { addDays, format, isBefore } from 'date-fns';
import {
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import api from '../../../services/api';
import { exportData } from '../../../utils/xlsx';

import { Container, FilterContainer } from './styles';
import Button from '../../../components/Button';
import ErrorMessage from '../../../components/ErrorMessage';
import { useToast } from '../../../hooks/toast';
import RegisterHeader from '../../../components/RegisterHeader';

enum FILTER_TYPES {
  ORDER_NUMBER = '1',
  DATE = '2',
}

interface IQRCode {
  productName: string;
  productInternalCode: string;
  order_number: string;
  line_num: number;
  code: string;
  selected: boolean;
  customersInternalCode: string;
  serial: string;
  customer_product_cod: string;
  customer_product_description: string;
  qrcode_label: string;
}

interface IProduct {
  productName: string;
  productInternalCode: string;
  selected: boolean;
}

const QRCodes: React.FC = () => {
  const [formData, setFormData] = useState({
    order_number_initial: '',
    order_number_final: '',
    date_initial: '',
    date_final: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [qrCodes, setQRCodes] = useState<IQRCode[]>([]);
  const { addToast } = useToast();
  const [selectedFilter, setSelectedFilter] = useState(
    FILTER_TYPES.ORDER_NUMBER,
  );
  const [products, setProducts] = useState<IProduct[]>([]);
  const [qtyColumns, setQtyColumns] = useState('4');
  const [currentDate, setCurrentDate] = useState(format(new Date(), 'MM-yyyy'));

  const handleCheckProduct = useCallback(
    (productInternalCode: string) => {
      const findProductIdx = products.findIndex(
        product => product.productInternalCode === productInternalCode,
      );

      if (findProductIdx < 0) return;

      const isChecked = products[findProductIdx].selected;

      setProducts(state =>
        state.map((product, index) =>
          index === findProductIdx
            ? {
                ...product,
                selected: !isChecked,
              }
            : product,
        ),
      );

      setQRCodes(state =>
        state.map(qrCode =>
          qrCode.productInternalCode === productInternalCode
            ? {
                ...qrCode,
                selected: !isChecked,
              }
            : qrCode,
        ),
      );
    },
    [products],
  );

  const formatResponse = (data: any) => {
    return data.map((qrCode: any) => ({
      productName: qrCode.productName,
      productInternalCode: qrCode.productInternalCode,
      order_number: qrCode.order_number,
      line_num: qrCode.lineNum,
      code: qrCode.code,
      customersInternalCode: qrCode.customersInternalCode,
      serial: qrCode.serial,
      customer_product_cod: qrCode.customer_product_cod ?? '',
      customer_product_description: qrCode.customer_product_description ?? '',
      qrcode_label: qrCode.qrcodelabel,
      selected: true,
    }));
  };

  const formatProducts = (data: any) => {
    const productsArray: IProduct[] = [];

    data.forEach((qrCode: any) => {
      const alreadyAdded = productsArray.find(
        p => p.productInternalCode === qrCode.productInternalCode,
      );

      if (!alreadyAdded) {
        productsArray.push({
          productName: qrCode.productName,
          productInternalCode: qrCode.productInternalCode,
          selected: true,
        });
      }
    });

    setProducts(productsArray);
  };

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setIsSubmitting(true);

      setCurrentDate(format(new Date(), 'MM-yyyy'));

      const {
        date_final,
        date_initial,
        order_number_final,
        order_number_initial,
      } = formData;

      try {
        setErrorMessage('');

        if (selectedFilter === FILTER_TYPES.ORDER_NUMBER) {
          if (!order_number_initial || !order_number_final) {
            setErrorMessage('Você precisa preencher todos os campos');
            return;
          }

          if (Number(order_number_final) < Number(order_number_initial)) {
            setErrorMessage(
              'A número do produto máximo deve ser maior que a mínimo',
            );
            return;
          }

          const response = await api.get<IQRCode[]>('/codes/qr', {
            params: {
              order_number_initial,
              order_number_final,
            },
          });

          const formattedResponse = formatResponse(response.data);
          formatProducts(response.data);
          setQRCodes(formattedResponse);
          return;
        }
        if (selectedFilter === FILTER_TYPES.DATE) {
          if (!date_initial || !date_final) {
            setErrorMessage('Você precisa preencher todos os campos');
            return;
          }

          if (isBefore(new Date(date_final), new Date(date_initial))) {
            setErrorMessage('A data máxima deve ser maior que a mínima');
            return;
          }

          const response = await api.get<IQRCode[]>('/codes/qr', {
            params: {
              date_initial,
              date_final,
            },
          });

          const formattedResponse = formatResponse(response.data);
          formatProducts(response.data);
          setQRCodes(formattedResponse);
          return;
        }

        const response = await api.get<IQRCode[]>('/codes/qr');

        const formattedResponse = formatResponse(response.data);
        formatProducts(response.data);
        setQRCodes(formattedResponse);
      } catch (error: any) {
        if (error.response && error.response.data) {
          addToast({
            title: 'Erro',
            type: 'error',
            description: error.response.data.message,
          });
        } else {
          addToast({
            title: 'Erro',
            type: 'error',
            description:
              'Ocorreu um erro ao tentar filtrar os QR Codes, tente novamente.',
          });
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [addToast, formData, selectedFilter],
  );

  const resetFormData = () => {
    setFormData({
      date_final: '',
      date_initial: '',
      order_number_final: '',
      order_number_initial: '',
    });
  };

  useEffect(() => {
    setErrorMessage('');
    resetFormData();
  }, [selectedFilter]);

  const handleSelectChange = useCallback(async event => {
    setSelectedFilter(event.target.value);
  }, []);

  const handleExportData = useCallback(() => {
    setCurrentDate(format(new Date(), 'MM-yyyy'));

    if (qtyColumns === '4') {
      const data = qrCodes
        .filter(qrCode => qrCode.selected)
        .map(qrCode => [
          qrCode.code,
          qrCode.qrcode_label,
          currentDate,
          qrCode.productInternalCode,
        ]);

      exportData({
        columns: ['WO', 'QR_CODES', 'DATE', 'ProductCode'],
        rows: data,
        filename: 'relatorio_qrcode',
      });
    } else {
      const data = qrCodes
        .filter(qrCode => qrCode.selected)
        .map(qrCode => [
          qrCode.code,
          qrCode.qrcode_label,
          currentDate,
          qrCode.productInternalCode,
          qrCode.customersInternalCode,
          qrCode.productName,
          qrCode.serial,
          qrCode.customer_product_cod,
          qrCode.customer_product_description,
        ]);

      exportData({
        columns: [
          'WO',
          'QR_CODES',
          'DATE',
          'ProductCode',
          'CustomerCode',
          'NAME',
          'SERIAL',
          'CUSTOMER_PRODUCT_COD',
          'CUSTOMER_PRODUCT_DESCRIPTION',
        ],
        rows: data,
        filename: 'relatorio_qrcode',
      });
    }
  }, [currentDate, qrCodes, qtyColumns]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleTransferArea = () => {
    let strWoItems = '';
    if (qtyColumns === '4') {
      qrCodes.forEach((qrCode: any) => {
        if (qrCode.selected) {
          strWoItems += `${qrCode.code}\t${qrCode.qrcode_label}\t${currentDate}\t${qrCode.productInternalCode}\n`;
        }
      });
    }
    if (qtyColumns === '6') {
      qrCodes.forEach((qrCode: any) => {
        if (qrCode.selected) {
          strWoItems += `${qrCode.code}\t${qrCode.qrcode_label}\t${currentDate}\t${qrCode.productInternalCode}\t${qrCode.customersInternalCode}\t${qrCode.productName}\t${qrCode.serial}\t${qrCode.customer_product_cod}\t${qrCode.customer_product_description}\n`;
        }
      });
    }
    navigator.clipboard.writeText(strWoItems);
  };

  return (
    <Container>
      <RadioGroup onChange={value => setQtyColumns(value)} defaultValue="4">
        <Flex direction="row">
          <Radio id="4" value="4" paddingRight="5">
            4 Colunas
          </Radio>
          <Radio id="6" value="6">
            6 Colunas
          </Radio>
        </Flex>
      </RadioGroup>

      <RegisterHeader
        downloadDisabled={!qrCodes.length}
        handleExportData={handleExportData}
      />
      <hr />
      <FilterContainer>
        <Stack spacing={2}>
          <div>
            <p>Filtrar por</p>
            <Select
              id="filter"
              name="filter"
              value={selectedFilter}
              onChange={handleSelectChange}
            >
              <option value="1">Número do pedido</option>
              <option value="2">Data do pedido</option>
            </Select>
          </div>
          <Stack
            direction="row"
            spacing={2}
            align="center"
            as="form"
            onSubmit={handleSubmit}
          >
            {selectedFilter === FILTER_TYPES.ORDER_NUMBER ? (
              <>
                <InputGroup>
                  <InputLeftAddon children="WO" />
                  <Input
                    name="order_number_initial"
                    type="text"
                    placeholder="Número Inicial"
                    value={formData.order_number_initial}
                    onChange={handleInputChange}
                    onBlur={() =>
                      !formData.order_number_final &&
                      setFormData(prev => ({
                        ...prev,
                        order_number_final: prev.order_number_initial,
                      }))
                    }
                  />
                </InputGroup>
                <Box>Até</Box>
                <InputGroup>
                  <InputLeftAddon children="WO" />
                  <Input
                    name="order_number_final"
                    type="text"
                    placeholder="Número Final"
                    value={formData.order_number_final}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </>
            ) : (
              selectedFilter === FILTER_TYPES.DATE && (
                <>
                  <InputGroup>
                    <Input
                      name="date_initial"
                      type="date"
                      placeholder="Número Inicial"
                      value={formData.date_initial}
                      onChange={handleInputChange}
                      onBlur={() =>
                        setFormData(prev => ({
                          ...prev,
                          date_final: format(
                            addDays(new Date(prev.date_initial), 3),
                            'yyyy-MM-dd',
                          ).toString(),
                        }))
                      }
                    />
                  </InputGroup>
                  <Box>Até</Box>
                  <InputGroup>
                    <Input
                      name="date_final"
                      type="date"
                      placeholder="Número Final"
                      value={formData.date_final}
                      onChange={handleInputChange}
                      onBlur={() =>
                        setFormData(prev => ({
                          ...prev,
                          date_initial: format(
                            addDays(new Date(prev.date_final), -1),
                            'yyyy-MM-dd',
                          ).toString(),
                        }))
                      }
                    />
                  </InputGroup>
                </>
              )
            )}
            <Button type="submit" size="md" isLoading={isSubmitting}>
              Filtrar
            </Button>
          </Stack>
          <Stack>
            <ErrorMessage message={errorMessage} />
          </Stack>
          {selectedFilter === FILTER_TYPES.ORDER_NUMBER && (
            <Stack>
              <Text fontWeight="semibold" fontSize="lg">
                Produtos ({products.length})
              </Text>
              {!!products.length && (
                <Stack
                  overflow="scroll"
                  padding="1"
                  maxH="sm"
                  backgroundColor="gray.100"
                >
                  {products.map(product => (
                    <Flex
                      direction="row"
                      key={product.productInternalCode}
                      border="1px"
                      borderColor="gray.200"
                      padding={2}
                      backgroundColor="white"
                    >
                      <Checkbox
                        isChecked={product.selected}
                        spacing={8}
                        onChange={() =>
                          handleCheckProduct(product.productInternalCode)
                        }
                      >
                        {`${product.productInternalCode} | ${product.productName}`}
                      </Checkbox>
                    </Flex>
                  ))}
                </Stack>
              )}
            </Stack>
          )}
        </Stack>

        <div />
      </FilterContainer>
      <Flex direction="column" width="100%">
        <Text fontWeight="semibold" fontSize="lg" mb={4}>
          Itens ({qrCodes.length}):
        </Text>
        <Stack spacing={2} width="100%">
          <Flex
            direction="column"
            borderRadius={6}
            borderColor="gray.200"
            borderWidth={2}
            width="100%"
            padding={4}
          >
            <Button
              onClick={() => {
                handleTransferArea();
              }}
            >
              Copiar WOs para gerar etiquetas (Equivalente ao Ctrl+c)
            </Button>
            {qrCodes.length ? (
              qrCodes.map((qrCode, index) =>
                qrCode.selected ? (
                  <p key={index}>
                    {qrCode.code}
                    {' | '}
                    {qrCode.code}
                    {' | '}
                    {currentDate}
                    {' | '}
                    {qrCode.productInternalCode}
                    {qtyColumns === '6' &&
                      ` | ${qrCode.customersInternalCode} | ${qrCode.productName} | ${qrCode.serial} | ${qrCode.customer_product_cod} | ${qrCode.customer_product_description}`}
                  </p>
                ) : (
                  ''
                ),
              )
            ) : (
              <Text>Não encontramos nenhum pedido registrado</Text>
            )}
          </Flex>
        </Stack>
      </Flex>
    </Container>
  );
};

export default QRCodes;
